import SockJs from "sockjs-client";
import Stomp from "webstomp-client";
import Store from "@/store";

//const baseUrl = "http://67.205.186.254:8080/ws";
const baseUrl = "https://qnft.io/wss";

const marketplace = {
  RECEIVE: "/user/marketplace/tokens/receive",
  UPDATE: "/user/marketplace/tokens/update",
};

const rates = {
  BIDS: (blockChainId) => `/user/${blockChainId}/bids/receive`,
  HISTORY: (blockChainId) => `/user/${blockChainId}/history/receive`,
};

const auction = {
  UPDATE: (blockChainId) => `/user/${blockChainId}/auction/update`,
};

let stompClient = null;

export default {
  initMarketplaceSocketConnection() {
    stompClient = null;
    const socket = new SockJs(baseUrl);
    stompClient = Stomp.over(socket);
    stompClient.debug = () => {};
    stompClient.connect({}, () => {
      stompClient.subscribe(marketplace.RECEIVE, (tick) => {
        Store.commit("marketplace/setToken", JSON.parse(tick.body));
      });

      stompClient.subscribe(marketplace.UPDATE, (tick) => {
        Store.commit("marketplace/setUpdatedToken", JSON.parse(tick.body));
      });
    });
  },

  initRatesSocketConnection(blockChainId) {
    stompClient = null;
    const socket = new SockJs(baseUrl);
    stompClient = Stomp.over(socket);
    stompClient.debug = () => {};
    stompClient.connect({}, () => {
      stompClient.subscribe(rates.BIDS(blockChainId), (tick) => {
        Store.commit("rate/setBid", JSON.parse(tick.body));
      });
      stompClient.subscribe(rates.HISTORY(blockChainId), (tick) => {
        Store.commit("rate/setHistory", JSON.parse(tick.body));
      });
      stompClient.subscribe(auction.UPDATE(blockChainId), (tick) => {
        Store.commit("auction/setArtwork", JSON.parse(tick.body));
      });
    });
  },

  disconnectSocket() {
    stompClient.disconnect();
  },
};
