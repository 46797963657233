<template>
  <div>
    <AppHeader />
    <keep-alive :include="savedRoutes" v-if="savedRoutes">
      <router-view />
    </keep-alive>
    <router-view v-else />
    <AppFooter v-if="$route.name !== routeNames().SIGN_IN" />
    <AppSprite />
  </div>
</template>

<script>
import AppHeader from "@/components/containers/AppHeader";
import AppFooter from "@/components/containers/AppFooter";
import AppSprite from "@/components/containers/AppSprite";
import socket from "@/api/socket";
import { mapActions, mapMutations } from "vuex";
import routeNames from "@/util/routeNames";

export default {
  name: "RootStack",
  data: () => ({
    savedRoutes: "",
  }),
  methods: {
    routeNames() {
      return routeNames;
    },
    ...mapMutations({
      setLanguage: "language/setLanguage",
    }),
    ...mapActions({
      reinstallI18n: "language/reinstallI18n",
    }),
  },
  watch: {
    $route(newValue, oldValue) {
      if (this.$route.name === "Artworks") {
        this.savedRoutes = "Artworks";
        socket.initMarketplaceSocketConnection();
        return;
      }
      if (this.$route.name === "Artwork") {
        socket.initRatesSocketConnection(this.$route.params.id);
      }
      if (oldValue.name === "Artwork") {
        socket.disconnectSocket();
      }
      if (oldValue.name === "Artworks" && newValue.name !== "Artwork") {
        this.savedRoutes = null;
        socket.disconnectSocket();
      }

      if (this.$route.name === "Collections") {
        this.savedRoutes = "Collections";
        return;
      }
      if (oldValue.name === "Collections" && newValue.name !== "Collection") {
        this.savedRoutes = null;
      }
    },
  },
  mounted() {
    const lang = localStorage.getItem("lang") || this.$route.params.lang || "";
    this.setLanguage(lang);
    this.reinstallI18n(lang);
    if (this.$route.name === "Artworks") {
      this.savedRoutes = "Artworks";
      socket.initMarketplaceSocketConnection();
    }
    if (this.$route.name === "Collections") {
      this.savedRoutes = "Collections";
    }
    if (this.$route.name === "Artwork") {
      socket.initRatesSocketConnection(this.$route.params.id);
    }
  },
  components: {
    AppFooter,
    AppHeader,
    AppSprite,
  },
};
</script>
