<template>
  <footer id="footer" class="section">
    <div>
      <router-link :to="homeLink()" class="logo">
        <img src="@/assets/img/qnft.svg" alt="QNFT" />
      </router-link>
    </div>

    <div class="terms">
      <router-link :to="privacyPolicyLink()">Privacy Policy</router-link>
      <router-link :to="termsOfServiceLink()">Terms of Service</router-link>
    </div>
  </footer>
  <!--[ #footer ]-->
</template>

<script>
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "AppFooter",
  mixins: [useLinkMixin],
};
</script>
